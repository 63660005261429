import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot} from "../../../../components/ui/input-otp.jsx";
import {Button} from "../../../../components/ui/button.jsx";
import otp from "/assets/otp_creative.png";
import {setFieldValue} from "../../../slices/slices.js";

export default function TextOtpInput(props) {
    const {renderValue, handleNext, disabledField} = props;
    const {components, step, brandingData} = useSelector(state => state.userSlice);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();
    const messageLength = components?.[step]?.data?.message?.length || 4;

    return (<div className={'w-full flex flex-col items-center gap-6'}>
        <div>
            <img src={otp} className={'object-contain'}/>
        </div>
        <div>
            <p className={'font-normal text-[24px] sm:text-[20px] text-center leading-[32px]'}>Enter {messageLength} digit
                verification
                code send to
                your
                number </p>
        </div>
        <InputOTP
            maxLength={messageLength || 4}
            value={renderValue("OTP_INPUT", components?.[step]?.value, components?.[step]?.data?.interface_block_id)}
            onChange={(value) => {
                const tempArray = components?.map(item => ({
                    ...item, value: item.value || '', file: item.file || ''
                }));
                tempArray[step]['value'] = value;
                dispatch(setFieldValue(tempArray));
            }}
        >
            {Array.from({length: messageLength}).map((_, i) => {

                // Calculate the group size for dividing the OTP input fields
                const groupSize = messageLength === 6 ? 3 : Math.ceil(messageLength / 2);

                return (
                    <React.Fragment key={i}>
                        <InputOTPGroup>
                            <InputOTPSlot index={i}/>
                        </InputOTPGroup>
                        {(i + 1) % groupSize === 0 && i + 1 < messageLength && (
                            <InputOTPSeparator>-</InputOTPSeparator>
                        )}
                    </React.Fragment>
                );
            })}
        </InputOTP>
        <div>
            <Button
                style={{background: brandingData?.accent_colour ? '#' + brandingData?.accent_colour : '#000'}}
                size={'sm'}
                disabled={components?.[step]?.value?.length !== components?.[step]?.data?.message?.length || disabledField}
                onClick={() => {
                    const payload = {
                        interaction_type: 'OTP_INPUT', value: components?.[step]?.value
                    }
                    handleNext(payload);
                }}>

                Proceed
            </Button>
        </div>
    </div>)
}
