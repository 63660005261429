import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Select,
  SelectLabel,
  SelectTrigger,
  SelectValue,
  SelectItem,
  SelectContent,
} from "../../../../components/ui/select.jsx";
import { setFieldValue } from "../../../slices/slices.js";
import { Button } from "../../../../components/ui/button.jsx";

export default function SelectInput(props) {
  const {
    renderValue,
    handleNext,
    disabledField,
    component,
    index,
    formInput,
    onChange,
  } = props;
  const { components, step, brandingData, appLoader } = useSelector(
    (state) => state.userSlice
  );
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  console.log("Component", component[index], index, formInput);

  const handleSubmit = (e) => {
    e.preventDefault();
    const filterArray = components[
      step
    ]?.data?.message?.section?.[0]?.items?.filter((item) =>
      item?.name == components[step]?.value ? item : null
    );
    const payload = {
      interaction_type: "SELECT_INPUT",
      title: components[step]?.value,
      reference_id: filterArray[0]?.reference_id,
    };
    handleNext(payload);
  };

  return (
    <form className={`w-full ${formInput ? "" : "space-y-4"}`} onSubmit={handleSubmit}>
      <div className={`${formInput ? "hidden" : "visible"}`}>
        <h1 className={`leading-snug font-semibold text-[24px] sm:text-[20px]`}>
          {formInput
            ? component[index]?.header
            : components[step]?.data?.message?.header}
        </h1>
        <p className={"my-1"}>
          {formInput
            ? component[index]?.body_message
            : components[step]?.data?.message?.body_message}
        </p>
      </div>
      <Select
        required={formInput ? true : false}
        disabled={disabledField}
        value={renderValue(
          "SELECT_INPUT",
          formInput ? component[index]?.value : components[step]?.value,
          formInput
            ? index
            : components?.[step]?.data?.interface_block_id
        )}
        onValueChange={(value) => {
          if (formInput) {
            onChange(value, index);
          } else {
            const tempArray = components?.map((item) => ({
              ...item,
              value: item.value || "",
              file: item.file || "",
            }));
            tempArray[step].value = value;
            dispatch(setFieldValue(tempArray));
          }
        }}
      >
        <SelectTrigger>
          <SelectValue
            placeholder={
              formInput
                ? component[index]?.section[0]?.title
                : components[step]?.data?.message?.section?.[0]?.title
            }
          />
        </SelectTrigger>
        <SelectContent>
          {(formInput
            ? component[index]?.section[0]?.items
            : components[step]?.data?.message?.section?.[0]?.items
          )?.map((item, step) => (
            <SelectItem className={"my-1 border"} value={item.name}>
              {item.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Button
        className={`${formInput ? "hidden" : "visible"}`}
        type={"button"}
        disabled={disabledField || appLoader}
        onClick={handleSubmit}
      >
        Proceed
      </Button>
    </form>
  );
}
