import {combineReducers, configureStore} from '@reduxjs/toolkit'
import userSlice from "/src/slices/slices.js";
import {credentialReducer} from "./slices/slices.js";
import {persistStore, persistReducer} from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
// import hardSet from "redux-persist/es/stateReconciler/hardSet";
// import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
//
// const persistConfig = {
//     key: 'root',
//     storage,
//     whitelist: ['userSlice'],
//     blacklist: ['credentialReducer'],
//     stateReconciler: autoMergeLevel2
// }

const rootReducer = combineReducers({
    userSlice,
    credentialSlice: credentialReducer
});

const appReducer = (state, action) => {
    return rootReducer(state, action);
}

// const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: appReducer,
});
// let persistor = persistStore(store)

export {store}
