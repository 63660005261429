import React, {useEffect, useRef, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import ProductionItem from "../../pages/ProductItem/ProductItem.jsx";

export default function StepperComponent(props) {
    const {socket} = props;
    const {isInitialised, step, appLoader, components,} = useSelector(state => state.userSlice);
    const {error} = useSelector(state => state.credentialSlice);
    const {loader} = useSelector(state => state.credentialSlice);
    const currentRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentRef.current) {
            currentRef.current.lastElementChild.scrollIntoView({behavior: 'smooth', block: 'end'});
        }

    }, [components?.length, currentRef])

    return (
        <ProductionItem
            socket={socket}
            currentIndex={step}
            index={step}
        />
    )
}
