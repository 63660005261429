"use client";
import {cn} from "../../lib/utils.js";
import {AnimatePresence, motion} from "framer-motion";
import {useState, useEffect, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {CircleDot, CircleX} from "lucide-react";
import {
    setPrevious, setSteps
} from "../../src/slices/slices.js";
import {startCase} from "lodash";

const CheckIcon = ({className}) => {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className={cn("w-6 h-6 ", className)}
    >
        <path d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
    </svg>);
};

const CheckFilled = ({className}) => {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className={cn("w-5 h-5", className)}
    >
        <path
            fillRule="evenodd"
            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
            clipRule="evenodd"
        />
    </svg>);
};

const LoaderCore = ({loadingStates,}) => {
    const {traceData, step, components, progressState} = useSelector(state => state.userSlice);
    const renderStatusIcon = useCallback((item) => {
        const isStatusCompleted = traceData?.some(data => data?.data?.interface_block_type === item?.data?.interface_block_type && data?.data?.message?.block_current_status === 'COMPLETED');
        const isStatusInitialized = traceData?.some(data => data?.data?.interface_block_type === item?.data?.interface_block_type && data?.data?.message?.block_current_status === 'INITIALIZED');
        const isStatusFailed = traceData?.some(data => data?.data?.interface_block_type === item?.data?.interface_block_type && data?.data?.message?.block_current_status === 'FAILED')
        const isStatusHalt = components?.some(item => item.data?.message?.message_type == 'HALT');
        if (isStatusCompleted) {
            return (<CheckFilled className={'text-green-500 mt-2'}/>)
        }
        if (isStatusHalt || isStatusFailed) {
            return <CircleX className={'text-red-500 mt-2'}/>
        }
        return <CircleDot className={'text-yellow-500  mt-2'}/>
    }, [traceData, step, components]);

    const renderTitle = useCallback((item) => {
        // const currentComponentItem = traceData.find(state => state?.data?.message?.block_type == item.data?.interface_block_type && state?.data?.message?.block_id == item?.data?.interface_block_id);
        const currentStepData = progressState.find(state => state.block_id === item?.data?.interface_block_id);
        console.log('Current Component Item', currentStepData, item, progressState);
        return currentStepData;
    }, [traceData, step, progressState, components])

    return (<div
        className="flex   relative justify-start max-w-xl mx-auto flex-col divide divide-y-2 divide-gray-100/50">
        {components?.map((item, index) => {
            const isCurrentStep = index === step;
            const distance = Math.abs(index + 1 - (step + 1));

            const opacity = Math.max(1 - distance * 0.2, 0);
            const currentStepData = renderTitle(item);
            console.log('current data', currentStepData?.humanized_label, distance, opacity);
            return (<>
                <motion.div
                    key={index}
                    className={cn("text-left flex gap-2 mt-2 mb-2 items-start")}
                    initial={{opacity: 0, y: -((step + 1) * 40)}}
                    animate={{opacity: opacity, y: -((step + 1) * 40)}}
                    transition={{duration: 0.5}}
                >
                    {renderStatusIcon(item)}
                    <div>
                        <p
                            className={cn("text-black dark:text-white", step === index && "text-black text-[24px] dark:text-lime-500 opacity-100")}
                        >
                            {startCase(currentStepData?.humanized_label?.replaceAll('Block', ' '))}
                        </p>
                        <p className={'text-[14px]'}>{startCase(currentStepData?.description)}</p>
                    </div>
                </motion.div>
            </>)
        })}
    </div>)
};

export const MultiStepLoader = ({
                                    loadingStates, loading,
                                }) => {
    const {step} = useSelector(state => state.userSlice);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSteps(loadingStates.length - 1));
    }, [loading, loadingStates.length]);

    return (<AnimatePresence mode="wait">
        <motion.div
            initial={{
                opacity: 0,
            }}
            animate={{
                opacity: 1,
            }}
            exit={{
                opacity: 0,
            }}
            className="w-full h-full  z-[100] flex items-center justify-start"
        >
            <div className={'h-60'}>
                <LoaderCore value={step} loadingStates={loadingStates}/>
            </div>

            <div
                className="bg-gradient-to-t inset-x-0 z-20 bottom-0 bg-white dark:bg-black h-full  [mask-image:radial-gradient(900px_at_center,transparent_30%,white)]"/>
        </motion.div>
    </AnimatePresence>);
};