import {createSlice, current} from "@reduxjs/toolkit";
import {getUserInitialize} from "./actions.js";
import {sortBy} from "lodash";

const credentialState = {
    token: '', error: '', initialized: false, loader: false
}

const initialSlice = {
    step: 0,
    components: [],
    replayComponentsData: [],
    formInputFields:[],
    redirect_url: '',
    loader: true,
    appLoader: false,
    otp: '',
    error: '',
    image: '',
    initialized: false,
    userData: null,
    elementResponse: null,
    senderResponse: null,
    recieverResponse: null,
    surepassNumber: '',
    recieverNumber: '',
    eventType: '',
    formData: null,
    interfaceSession: null,
    file: null,
    nextEventTypeData: null,
    brandingData: null,
    eventData: null,
    interfaceBlockType: '',
    textData: '',
    event: '',
    otpSent: false,
    initialLoad: true,
    stepsArray: [0],
    traceData: [],
    brandIcon: '',
    selectItem: '',
    progressState: [],
    nextEventData: null,
    isNext: true,
    isPrevious: false
}

function checkArrayItemPresent(step, stepArray) {
    console.log("Step", step, stepArray);
    if (stepArray?.includes(step)) {
        return true;
    }
    return false;
}

const credentialSlice = createSlice({
    name: 'credential', initialState: credentialState, reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload;
        }, setErrorMessage: (state, action) => {
            state.error = action.payload;
        }
    }, extraReducers: builder => builder.addCase(getUserInitialize.pending, (state, action) => {
        state.loader = true;
        state.error = '';
    }).addCase(getUserInitialize.fulfilled, (state, {type, payload}) => {
        state.initialized = true;
        state.token = payload?.authorization?.access_token;
    }).addCase(getUserInitialize.rejected, (state, {type, payload}) => {
        state.error = payload?.data?.detail;
        state.loader = false;
    })
});

const hostedSlices = createSlice({
    name: 'hosted', initialState: initialSlice, reducers: {
        setProgressState: (state, {payload}) => {
            state.progressState = payload;
        }, setNext: (state, {payload}) => {
            state.isNext = payload;
        }, setTraceData: (state, {payload}) => {
            state.traceData = state.traceData?.length ? [...state.traceData, ...payload] : payload;
        }, setPrevious: (state, {payload}) => {
            state.isPrevious = payload;
        }, setFieldValue: (state, {payload}) => {
            state.components = payload;
        }, setStepsArray: (state, {payload}) => {
            let isPresent = checkArrayItemPresent(payload, state.stepsArray);
            state.stepsArray = !isPresent ? [...state.stepsArray, payload] : state.stepsArray
        }, setFormInputFields: (state, {payload}) => {
            state.formInputFields = payload;
        }, setError: (state, {payload}) => {
        }, setError: (state, {payload}) => {
            state.error = payload || '';
        }, setAppLoader: (state, {payload}) => {
            state.appLoader = payload;
        }, setTextData: (state, {payload}) => {
            state.textData = payload
        }, setFile: (state, {payload}) => {
            state.file = payload;
        }, setElementData: (state, {payload}) => {
            state.elementResponse = payload || null;
        }, clearState: (state) => {
            return state = initialSlice;
        }, setSelectItem: (state, {payload}) => {
            state.selectItem = payload;
        }, setReplayComponents: (state, {payload}) => {
            state.replayComponentsData = payload?.interactions;
            state.initialLoad = false;
        }, setOtpValue: (state, {payload}) => {
            state.otp = payload;
        }, setOtpSent: (state, {payload}) => {
            state.otpSent = payload;
        }, setComponentData: (state, {payload}) => {
            const componentState = current(state.components);
            const progressState = current(state.progressState);
            const tempComponentState = componentState?.map(item => ({...item}));

            let payloadData = [];

            const hasStartBlock = (arr) => arr.some(item => item?.data?.interface_block_type === 'on_start_widget_block');
            const hasFlashMessageBlock = (arr) => arr.some(item => item?.data?.interface_block_type === 'flash_message_widget_block');
            const hasBothBlocks = (arr) => hasStartBlock(arr) && hasFlashMessageBlock(arr);

            const removeBlockByTypeAndMessageType = (arr, blockType, messageType) => {
                const index = arr.findIndex(item => item?.data?.interface_block_type === blockType && item?.data?.message?.message_type === messageType);
                if (index > -1) arr.splice(index, 1);
            };

            if (tempComponentState?.length) {
                if (hasBothBlocks(tempComponentState)) {
                    removeBlockByTypeAndMessageType(tempComponentState, 'on_start_widget_block', 'HINT');
                    removeBlockByTypeAndMessageType(tempComponentState, 'on_start_widget_block', 'TRACE');
                    removeBlockByTypeAndMessageType(tempComponentState, 'flash_message_widget_block', 'TRACE');
                } else if (hasFlashMessageBlock(tempComponentState) && hasStartBlock(payload)) {
                    removeBlockByTypeAndMessageType(payload, 'on_start_widget_block', 'TRACE');
                    removeBlockByTypeAndMessageType(payload, 'on_start_widget_block', 'HINT');
                    removeBlockByTypeAndMessageType(tempComponentState, 'flash_message_widget_block', 'TRACE');
                } else if (hasStartBlock(tempComponentState) && hasFlashMessageBlock(payload)) {
                    removeBlockByTypeAndMessageType(tempComponentState, 'on_start_widget_block', 'TRACE');
                    removeBlockByTypeAndMessageType(tempComponentState, 'on_start_widget_block', 'HINT');
                    removeBlockByTypeAndMessageType(payload, 'flash_message_widget_block', 'TRACE');
                }
                payloadData = [...tempComponentState, ...payload];

            } else if (payload?.length > 1) {
                const tempArray = payload.map(item => ({...item}));
                console.log("Temp Array", hasBothBlocks(tempArray), tempArray);
                if (hasBothBlocks(tempArray)) {
                    removeBlockByTypeAndMessageType(tempArray, 'on_start_widget_block', 'HINT');
                    removeBlockByTypeAndMessageType(tempArray, 'on_start_widget_block', 'TRACE');
                    removeBlockByTypeAndMessageType(tempArray, 'flash_message_widget_block', 'TRACE');

                    const filterBlockArray = tempArray.map(event => {
                        const matchingBlock = progressState.find(block => block.block_type === event.data?.interface_block_type);
                        return matchingBlock ? {...event} : null;
                    }).filter(item => item !== null);
                    
                    payloadData = [...tempArray];
                }
                else {
                    console.log("Payload Data", payload);
                    payloadData = [...payload];
                }
            } else {
                console.log("Payload Data", payload);
                payloadData = [...payload];
            }

            const finalArray = payloadData.filter((item, index, self) => {
                if (item?.data?.interface_block_type !== 'router_block') {
                    const firstIndex = self.findIndex(data => item?.data?.interface_block_id === data?.data?.interface_block_id);
                    return index === firstIndex || item?.data?.message?.message_type !== 'TRACE';
                }
                return false;
            });

            console.log("Final Array", finalArray, payloadData);

            // const tempArray = finalArray?.map(item => ({...item}));
            // const surepassGroup = tempArray?.filter(item => progressState?.some(data => (data.group === "SUREPASS" && item?.data?.interface_block_id == data?.block_id)) || progressState?.some(data => data.group === 'WIDGETS.RECEIVE' && item?.data?.message?.message_type == 'HINT') || progressState?.some(data => data.group === 'WIDGETS.SEND' && item?.data?.interface_block_type == 'flash_message_widget_block'));
            // console.log('Surepass Group', surepassGroup, finalArray, tempArray, progressState);
            state.event = payload[0]?.data?.message?.message_type;
            state.components = finalArray;
            state.appLoader = false;

        }, setNextEventData: (state, {payload}) => {
            state.event = payload?.[0].data.message.message_type || '';
            state.eventType = payload?.[0].data.message.interaction_type || '';
            state.nextEventData = payload ? state.nextEventData?.length ? [...state.nextEventData, ...payload] : payload : null;
        }, setImage: (state, {payload}) => {
            state.image = payload;
        }, setSessionData: (state, {payload}) => {
            state.interfaceSession = payload;
        }, setNextEvent: (state, {payload}) => {
            state.eventType = payload.interaction_type || '';
            state.nextEventTypeData = payload || null;
        }, setBrandingData: (state, {payload}) => {
            state.brandingData = payload;
        }, setRecieverNumber: (state, {payload}) => {
            state.recieverNumber = payload;
        }, setHintData: (state, {payload}) => {
            state.eventData = payload || null;
            state.eventType = payload?.interaction_type || '';

        }, setSenderResponse: (state, {type, payload}) => {
            state.senderResponse = payload;
        }, setRecieverResponse: (state, {type, payload}) => {
            state.recieverResponse = payload;
        }, setSteps: (state, {type, payload}) => {
            state.step = payload;
        }, setUserData: (state, {type, payload}) => {
            state.userData = payload;
        }, setBrandIcon: (state, {payload}) => {
            state.brandIcon = payload;
        }
    },
});

export const {
    setSteps,
    setStepsArray,
    setAppLoader,
    setPrevious,
    setNextEventData,
    setImage,
    setHintData,
    setElementData,
    setNextEvent,
    setFile,
    setTextData,
    setRecieverResponse,
    setComponentData,
    setSenderResponse,
    setError,
    setBrandIcon,
    setSelectItem,
    setOtpSent,
    setBrandingData,
    setSessionData,
    clearState,
    setOtpValue,
    setReplayComponents,
    setFieldValue,
    setNext,
    setTraceData,
    setProgressState,
    setFormInputFields,
} = hostedSlices.actions;

export const {setLoader, setErrorMessage} = credentialSlice.actions;

export default hostedSlices.reducer;
export const credentialReducer = credentialSlice.reducer
