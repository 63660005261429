import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callNextEventAPI } from "../../../services/api/api.js";
import { Button } from "../../../../components/ui/button.jsx";
import { setAppLoader, setFieldValue, setHintData } from "../../../slices/slices.js";
import { Camera, Upload, RefreshCcw, X } from "lucide-react";
import { Card, CardContent } from "../../../../components/ui/card.jsx";
import { useFilePicker } from "use-file-picker";
import { motion } from "framer-motion";
import { ScrollArea } from "../../../../components/ui/scroll-area.jsx";

import "./index.css";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

export default function CaptureComponent(props) {
    const { renderValue } = props;
    const { components, step } = useSelector((state) => state.userSlice);
    const dispatch = useDispatch();

    const [error, setError] = useState("");
    const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
    const [isMobile, setIsMobile] = useState(false);
    const [capturedImage, setCapturedImage] = useState(null);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    const isSelfieComponent = useMemo(() => {
        return components?.[step]?.data?.message?.label?.includes("selfie");
    }, [components, step]);

    const importantNotes = useMemo(
        () => [
            "Ensure your face is centered in the circle",
            "Keep a neutral expression",
            "Avoid shadows on your face",
            "Remove glasses if possible",
        ],
        []
    );

    const { openFilePicker } = useFilePicker({
        readAs: "DataURL",
        accept: "image/*",
        multiple: false,
        onFilesSelected: ({ plainFiles, filesContent }) => {
            if (filesContent) {
                handleFileInput(plainFiles[0], filesContent[0]?.content);
            }
        },
    });

    const handleFileInput = useCallback((file, content) => {

        if (
            components?.[step]?.data?.message?.content_type &&
            file.type !== components?.[step]?.data?.message?.content_type
        ) {
            setError(`Please upload ${components?.[step]?.data?.message?.content_type} format`);
            return;
        }

        const formData = new FormData();
        formData.append("interaction_type", "CAMERA_IMAGE_INPUT");
        formData.append("data", file);
        formData.append("filename", file.name);
        formData.append("content_type", file.type);
        formData.append('interface_block_id', components?.[step]?.data?.interface_block_id);

        const tempArray = components?.map((item) => ({
            ...item,
            value: item.value || "",
            file: item.file || "",
        }));
        tempArray[step].file = file;
        tempArray[step].value = content;

        dispatch(setFieldValue(tempArray));
        dispatch(setAppLoader(true));
        setCapturedImage(content);

        callNextEventAPI(formData)
            .then((res) => {
                dispatch(setHintData(res.data));
            })
            .catch((err) => setError(err.response?.data?.message));
    }, [components, step, dispatch]);

    const capturePhoto = useCallback(() => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const contentType = components?.[step]?.data?.message?.content_type || "image/jpeg";

        if (video && canvas) {
            const context = canvas.getContext("2d");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

            canvas.toBlob((blob) => {
                const file = new File([blob], `captured_image.${contentType?.split('/')[1]}`, { type: contentType });
                const reader = new FileReader();
                reader.onloadend = () => {
                    handleFileInput(file, reader.result);
                };
                reader.readAsDataURL(blob);
            }, contentType);
        }
    }, [handleFileInput]);

    const switchCamera = () => {
        setFacingMode((prevMode) =>
            prevMode === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
        );
    };

    const startVideoStream = useCallback(() => {
        const video = videoRef.current;

        if (video) {
            // Set the correct facing mode based on the camera we want to use
            const constraints = {
                audio: false,
                video: {
                    facingMode: facingMode, // "user" for front-facing camera, "environment" for rear camera
                },
            };

            // Attempt to get the media stream
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => {
                    video.srcObject = stream;
                    video.play();
                })
                .catch((err) => {
                    console.error(`Error accessing camera: ${err.name}: ${err.message}`);
                    setError("Unable to access camera. Please ensure you've granted camera permissions.");
                });
        }
    }, [facingMode]);

    useEffect(() => {
        startVideoStream();

        return () => {
            const video = videoRef.current;
            if (video && video.srcObject) {
                const tracks = video.srcObject.getTracks();
                tracks.forEach((track) => track.stop());
            }
        };
    }, [startVideoStream, step]);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    useEffect(() => {
        if (isMobile) {
            // If it's a mobile device, force switch to front camera for selfie
            setFacingMode(FACING_MODE_USER);
            startVideoStream();
        }
    }, [isMobile]);

    useEffect(() => {
        setCapturedImage(null);
    }, [step]);


    return (
        <div className="w-full max-w-md ">
            <div className="w-full p-4">
                <h2 className="text-xl font-semibold mb-2">{components?.[step]?.data?.message?.label}</h2>
                {isSelfieComponent && (
                    <Card className="border-none shadow-none">
                        <CardContent className="p-0 pb-4 rounded">
                            <h6 className="font-bold mb-2">Selfie Tips</h6>
                            <ul className="space-y-1 text-sm list-disc list-inside">
                                {importantNotes.map((note, index) => (
                                    <li key={index}>{note}</li>
                                ))}
                            </ul>
                        </CardContent>
                    </Card>
                )}
                <div className="flex flex-col justify-center items-center">
                    <div className="relative sm:w-[300px] sm:h-[300px] w-[400px] h-[400px] mb-4">
                        {renderValue("CAMERA_IMAGE_INPUT", components?.[step]?.value, components?.[step]?.data?.interface_block_id) ? (
                            <div className="relative w-full h-full">
                                <img
                                    src={
                                        typeof renderValue(
                                            "CAMERA_IMAGE_INPUT",
                                            components?.[step]?.value,
                                            components?.[step]?.data?.interface_block_id
                                        ) === "string"
                                            ? renderValue(
                                                "CAMERA_IMAGE_INPUT",
                                                components?.[step]?.value,
                                                components?.[step]?.data?.interface_block_id
                                            )
                                            : renderValue(
                                                "CAMERA_IMAGE_INPUT",
                                                components?.[step]?.value,
                                                components?.[step]?.data?.interface_block_id
                                            )?.image
                                    }
                                    alt="Captured"
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        ) : (
                            <>
                                <div className={`absolute inset-0 ${isSelfieComponent && 'rounded-full'} overflow-hidden`}>
                                    <video
                                        ref={videoRef}
                                        className="w-full h-full object-cover"
                                        autoPlay
                                        playsInline
                                        muted
                                    />
                                </div>
                                {isSelfieComponent && (
                                    <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                                        <div className="w-full h-full border-4 border-gray-200 rounded-full" />
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    <div className="flex sm:mx-0 justify-center mx-16 sm:flex-nowrap flex-wrap gap-4">
                        {!renderValue("CAMERA_IMAGE_INPUT", components?.[step]?.value) && (
                            <Button
                                variant="default"
                                onClick={capturePhoto}
                                className="flex items-center gap-2"
                            >
                                <Camera size={20} />
                                <span>Capture</span>
                            </Button>
                        )}
                        {isMobile && !capturedImage && !renderValue("CAMERA_IMAGE_INPUT", components?.[step]?.value) && (
                            <Button
                                variant="outline"
                                onClick={switchCamera}
                                className="flex items-center gap-2"
                            >
                                <RefreshCcw size={20} />
                                <span>Switch Camera</span>
                            </Button>
                        )}
                        <Button
                            variant="outline"
                            onClick={openFilePicker}
                            className="hidden items-center gap-2"
                        >
                            <Upload size={20} />
                            <span>Upload</span>
                        </Button>
                    </div>
                </div>

                {error && (
                    <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
                        {error}
                    </div>
                )}
            </div>
            <canvas ref={canvasRef} className="hidden" />
        </div>
    );
}
