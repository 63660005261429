import React, {useCallback, useEffect, useState, useRef, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "../../../../components/ui/button.jsx";
import {setPrevious, setSteps} from "../../../slices/slices.js";
import {ChevronDown, ChevronUp, CircleCheck} from "lucide-react";
import {MultiStepLoader as Loader} from "../../../../components/ui/mult-step-loader.jsx";
import {useSearchParams} from "react-router-dom";
import ProgressSteps from "../Steps/Steps.jsx";

export default function MainWrapper(props) {
    const {
        components, step, appLoader, brandIcon, brandingData, interfaceSession, traceData,
    } = useSelector((state) => state.userSlice);
    const containerRef = useRef(null);
    const [count, setCount] = useState([]);
    const {error} = useSelector((state) => state.credentialSlice);
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const signature = searchParams.get("signature");

    useEffect(() => {
        if (containerRef.current) {
            const currentBlock = document.querySelector('.horizontalStepperWrapper');
            if (currentBlock) {
                currentBlock.scrollIntoView({behavior: "smooth", block: "start"});
            }
        }
    }, [step]);

    const isDisabled = useMemo(() => {
        return components?.some(item => item?.data?.message?.message_type == 'HALT') || false;
    }, [components]);

    function renderBrandIcon() {
        if (brandingData?.icon_artifact?.artifact_id == '__mock_surepass_brand_icon_artifact__') {
            return "https://surepass.io/wp-content/uploads/2020/01/cropped-2-layers@2x-32x32.png"
        } else if (brandIcon) {
            return brandIcon;
        }
        return "https://surepass.io/wp-content/uploads/2020/01/cropped-2-layers@2x-32x32.png"
    }


    return (<div className="flex flex-col h-full w-full overflow-auto">
        {appLoader && (<progress className="pure-material-progress-linear"/>)}
        <div className={'flex-2 px-2'}>
            {brandIcon || brandingData ? <div
                className="h-[3rem]   top-0 w-full  gap-4 z-[1111] ">
                <div className={'flex my-2 justify-between h-full mt-2 gap-2 items-center'}>
                    <div className={"h-full"}>
                        <img
                            src={renderBrandIcon()}
                            className={"object-contain  h-full"}
                        />
                    </div>
                    <h1 className="font-[800] sm:text-[20px] text-[28px]">{brandingData?.brand_name}</h1>
                </div>

            </div> : null}
            <div className={"hidden sm:flex flex-row w-full my-2"}>
                <ProgressSteps currentStep={step} numberOfSteps={components?.length - 1}
                               stepArray={components}/>
                {/*{components?.map((item, index) => renderCurrentBlockTypeStatus(item, index, index === components.length - 1))}*/}
            </div>
        </div>
        <div className={"flex-1 relative "}>
            <div className="flex h-full flex-row sm:flex-col">
                <div id={'superflow-sidebar'}
                     className="px-4 flex flex-col w-[400px] min-w-[300px] sm:w-full sm:overflow-hidden"
                     ref={containerRef}>
                    <div className="h-full flex w-full items-center overflow-hidden">
                        <div className={"sm:hidden flex w-full flex-col"}>
                            <Loader loadingStates={components} steps={step}/>
                        </div>

                    </div>
                </div>
                <div className={"relative h-full w-full flex flex-col sm:overflow-auto"}>
                    <div
                        className={"container flex-1 sm:w-full max-w-[764px] w-[764px] h-full  my-auto px-2 sm:px-4 sm:overflow-auto"}>
                        {signature && props.children}
                    </div>

                </div>
            </div>
        </div>
        <div
            id={'footer-bottom'}
            className={"flex-2  gap-1 flex justify-end items-center py-2 w-full"}>
            <Button
                style={{background: brandingData?.accent_colour ? '#' + brandingData?.accent_colour : '#000'}}
                className={"sm:hidden rounded flex min-w-[80px] gap-2 items-center"}
                size={"sm"}
                disabled={step == 0 || isDisabled || appLoader}
                onClick={() => {
                    dispatch(setPrevious(true));
                    dispatch(setSteps(step - 1));
                }}
            >
                Back
                <ChevronUp size={16}/>
            </Button>
            <Button
                style={{background: brandingData?.accent_colour ? '#' + brandingData?.accent_colour : '#000'}}
                className={"hidden rounded sm:flex min-w-[40px] gap-2 items-center"}
                size={"sm"}
                disabled={step == 0 || isDisabled || appLoader}
                onClick={() => {
                    dispatch(setPrevious(true));
                    dispatch(setSteps(step - 1));
                }}
            >
                <ChevronUp size={16}/>
            </Button>
            <Button
                style={{background: brandingData?.accent_colour ? '#' + brandingData?.accent_colour : '#000'}}
                size={"sm"}
                className={"rounded mx-[0.1px] min-w-[40px] hidden sm:flex gap-2 items-center"}
                disabled={step === components?.length - 1 || !components?.length || isDisabled || appLoader}
                onClick={() => {
                    dispatch(setPrevious(false));
                    dispatch(setSteps(step + 1));
                }}
            >
                            <span>
                                   <ChevronDown size={16}/>
                            </span>
            </Button>
            <Button
                style={{background: brandingData?.accent_colour ? '#' + brandingData?.accent_colour : '#000'}}
                size={"sm"}
                className={"rounded mx-[0.1px] min-w-[80px] sm:hidden flex gap-2 items-center"}
                disabled={step === components?.length - 1 || !components?.length || isDisabled || appLoader}
                onClick={() => {
                    dispatch(setPrevious(false));
                    dispatch(setSteps(step + 1));
                }}
            >
                            <span>
                                Next
                            </span>
                <span>
                                   <ChevronDown size={16}/>
                            </span>
            </Button>
            <Button variant={'outline'} className={'font-semibold'}>Powered by Superflow</Button>
        </div>
    </div>);
}
