import React, {useState} from "react";
import {useSelector} from "react-redux";
import {callNextEventAPI} from "../../../services/api/api.js";
import {Button} from "../../../../components/ui/button.jsx";
import {Input} from "../../../../components/ui/input.jsx";
import {
    setAppLoader, setFieldValue, setSteps,
} from "../../../slices/slices.js";
import Dropzone from "react-dropzone";
import {ImageUp} from "lucide-react";
import {useDispatch} from "react-redux";
import {Card, CardContent} from "../../../../components/ui/card.jsx";

export default function FileInputComponent(props) {
    const {renderValue, disabledField} = props;
    const {components, step, appLoader} = useSelector(state => state.userSlice);
    const dispatch = useDispatch();
    const [error, setError] = useState('');

    const handleFileInput = (file) => {

        if (components?.[step]?.data?.message?.content_type && file[0].type !== components?.[step]?.data?.message?.content_type) {
            setError(`Please upload ${components?.[step]?.data?.message?.content_type} format`)
            return;
        }

        const formData = new FormData();
        formData.append('interaction_type', 'FILE_INPUT');
        formData.append('interface_block_id', components?.[step]?.data?.interface_block_id);
        formData.append('data', file[0]);
        formData.append('filename', file[0].name);
        formData.append('content_type', file[0].type);

        const tempArray = components?.map(item => ({...item, value: item.value || '', file: item.file || ''}));
        tempArray[step].file = file;

        const reader = new FileReader();
        reader.onload = ({target}) => {
            tempArray[step].value = target.result;
            dispatch(setFieldValue(tempArray));
        }

        if (file?.[0]?.type?.includes('image')) {
            reader.readAsDataURL(file?.[0]);
        }

        dispatch(setAppLoader(true));
        callNextEventAPI(formData).then(res => {
            dispatch(setHintData(res.data));
        }).catch(err => setError(err.response?.data?.message))
    }
    console.log('Type Check', components?.[step]?.data?.message?.interaction_type)

    return (<>
        {renderValue('FILE_INPUT', components?.[step]?.value, components?.[step]?.data?.interface_block_id) ? (

            <Card>
                <CardContent
                    className={`${appLoader && 'blur-[4px]'}  sm:max-w-sm p-0 max-w-sm  rounded space-y-2`}>
                    <img
                        src={typeof renderValue('FILE_INPUT', components?.[step]?.value, components?.[step]?.data?.interface_block_id) === 'string' ? renderValue('FILE_INPUT', components?.[step]?.value, components?.[step]?.data?.interface_block_id) : renderValue('FILE_INPUT', components?.[step]?.value, components?.[step]?.data?.interface_block_id)?.image}
                        className="w-full h-full object-contain rounded "
                    />
                    <p className="font-[500] px-2 leading-[40px]">
                        {typeof renderValue('FILE_INPUT', components?.[step]?.value, components?.[step]?.data?.interface_block_id) === 'string' ? components?.[step]?.file?.[0]?.path : renderValue('FILE_INPUT', components?.[step]?.value, components?.[step]?.data?.interface_block_id)?.filename}
                    </p>

                </CardContent>
            </Card>

        ) : <div className={'w-full'}>

            <form
                className={'bg-muted py-2 rounded flex justify-center items-center gap-4  border border-slate-400'}>


                <Dropzone
                    disabled={renderValue('FILE_INPUT', '', components?.[step]?.data?.interface_block_id) || disabledField}
                    multiple={false}
                    maxFiles={1}
                    onDrop={(e) => handleFileInput(e, step)}>
                    {({getRootProps, getInputProps}) => (<div
                        className={'w-3/4 flex  py-4 flex-col items-center justify-center gap-2 cursor-pointer '}
                        {...getRootProps()}>
                        <Input {...getInputProps()} />
                        <ImageUp size={40} className={'text-gray-800  font-normal'}/>
                        {components?.[step]?.data?.message?.label ? (
                                <p className={'text-gray-800 font-medium text-[16px] text-center '}>{components?.[step]?.data?.message?.label}</p>) :
                            <p className={'text-gray-800 font-medium text-[16px] text-center'}
                            >Drag
                                & drop
                                your
                                file here, or click
                                to
                                select file</p>}

                        <Button
                            type={'button'}
                            variant={'outline'}
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                        >
                            Upload File
                        </Button>
                    </div>)}
                </Dropzone>
            </form>
        </div>}
        {error && <span className={'text-red-400'}>{error}</span>}
    </>)
}
