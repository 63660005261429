import React from "react";
import errorImage from "/assets/error.jpg";
import {useSelector} from "react-redux";

export default function Error() {
    const {error} = useSelector(state => state.credentialSlice);
    return (
        <div className="w-full h-full flex flex-col gap-4 justify-center items-center p-4 sm:p-2" role="alert">
            <img src={errorImage} className="max-w-[400px] w-full sm:max-w-[300px] object-contain"
                 alt="Error illustration"/>
            <h1 className="text-[35px] sm:text-[24px] text-center font-semibold text-gray-600">Oops! Something went
                wrong!</h1>
            <span className="text-[20px] sm:text-[16px] text-center">{error}</span>
        </div>
    );
}
