import React, {useCallback, useMemo} from 'react';
import {useSelector} from "react-redux";
import {Avatar, AvatarFallback, AvatarImage} from "../../../../components/ui/avatar";
import {Progress} from "../../../../components/ui/progress";

export default function ProgressStep({currentStep, numberOfSteps, stepArray}) {

    const {
        components, step, traceData, progressState
    } = useSelector((state) => state.userSlice);

    const activeColor = (index) => currentStep >= index ? 'bg-gray-700' : 'bg-gray-200'
    const isFinalStep = (index) => index === numberOfSteps - 1;

    const renderTitle = useMemo(() => {
        const currentStepData = progressState.find(state => state.block_type === components[step]?.data?.interface_block_type);
        return currentStepData;
    }, [traceData, progressState, components, step]);

    const stepDetails = renderTitle;

    const progressValue = useMemo(() => {
        if (!components || components.length === 0) {
            return 0;
        }
        const value = (step / (components.length - 1)) * 100;
        return value;
    }, [step, components]);

    console.log("Progress value", progressValue, stepDetails);

    return (<div className={'w-full space-y-2'}>
        <div className={'flex gap-4 py-2'}>
            <div>
                <p className={'text-[16px] font-medium leading-[20px]'}>{stepDetails?.humanized_label}</p>
                <p className={'text-[12px] leading-[20px]'}>{stepDetails?.description}</p>
            </div>
        </div>
        <div>
            <Progress className={'h-[4px]'} value={progressValue}/>
        </div>
    </div>)
}