import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://f5190072e89de4aee91e636dfb81598d@o4507266352152576.ingest.us.sentry.io/4507266452422656",
    maxBreadcrumbs: 50,
    environment: 'production',
    integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/

    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});
