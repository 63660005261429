import {createAsyncThunk} from "@reduxjs/toolkit";
import {userInitializeAPI} from "../services/api/api.js";
import * as Sentry from "@sentry/react";

export const getUserInitialize = createAsyncThunk(
    'user/initialize',
    async (payload,
           {
               rejectWithValue
           }) => {
        try {

            const response = await userInitializeAPI(payload);
            const data = await response.data;

            //Set user in sentry
            Sentry.setUser({user: data});
            return data
        } catch (error) {
            if (error.response.status == 401) {
                // console.log('error', error.response);
                localStorage.clear();
            }
            return rejectWithValue(error.response)
        }
    }
)
