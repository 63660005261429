import React, {useMemo} from "react";
import {useSelector, useDispatch} from "react-redux";
import {replace} from "lodash";
import {Button} from "../../../../components/ui/button.jsx";
import {
    setAppLoader, setSteps
} from "../../../slices/slices.js";

export default function ElementComponent(props) {

    const {socket} = props;
    const {components, step, brandingData, appLoader, traceData, progressState} = useSelector(state => state.userSlice);
    const dispatch = useDispatch();

    return (<div className={'w-full space-y-2'}>
        <h3 className={`sm:text-[16px] ${components?.[step]?.data?.interface_block_type == 'flash_message_widget_block' ? 'flash-message sm:text-[20px]' : 'text-[20px]'}`}>
            {replace(components?.[step]?.data?.message?.text, /\*/g, '')}
        </h3>
        {components?.[step]?.data?.interface_block_type == 'flash_message_widget_block' && (
            <Button disabled={appLoader}
                    style={{background: brandingData?.accent_colour ? '#' + brandingData?.accent_colour : '#000'}}
                    onClick={() => {
                        dispatch(setAppLoader(true));
                        const startBlockId = progressState?.find(item => item?.block_type == components?.[step]?.data?.interface_block_type);
                        console.log("Start BlockId", startBlockId);
                        const blockId = components?.[step]?.data?.interface_block_id;
                        let payload = {
                            interface_block_id: blockId,
                            interaction_type: 'START_BUTTON',
                        };
                        socket.emit('interface_interaction', payload)
                    }}>
                Proceed
            </Button>)}
    </div>)
}
