import React, {useState, useMemo} from "react";
import {useSelector} from "react-redux";
import {Button} from "../../../../components/ui/button.jsx";
import {Input} from "../../../../components/ui/input.jsx";

export default function TextInputComponent(props) {
    const {renderValue, handleNext, elementType, disabledField, handleInput} = props;
    const {components, step, brandingData, appLoader} = useSelector(state => state.userSlice);
    const [errorMessage, setErrorMessage] = useState('');
    const maxLength = useMemo(() => {
        if (elementType == 'NUMERIC_INPUT') {
            return components?.[step]?.data?.message?.lt || undefined;
        } else {
            return components?.[step]?.data?.message?.max_length || undefined;
        }
    }, [elementType]);

    const minLength = useMemo(() => {
        if (elementType == 'NUMERIC_INPUT') {
            return components?.[step]?.data?.message?.gt || undefined;
        } else {
            return components?.[step]?.data?.message?.min_length || undefined;
        }
    }, [elementType])

    const handleSubmit = (e) => {
        e.preventDefault();
        const value = components?.[step]?.value;
        console.log("Input length", maxLength, minLength, value?.length);

        if (!value) {
            setErrorMessage(`Input field should not be empty`);
            return;
        } else if (components?.[step]?.data?.message?.validation_regex && !components?.[step]?.data?.message?.validation_regex?.test(value)) {
            setErrorMessage(`Regex error: Value does not match the required pattern. ${components?.[step]?.data?.message?.validation_regex}`);
            return;
        } else if (minLength && value?.length !== minLength) {
            setErrorMessage(`Input value must contain at least ${minLength} characters `);
            return;
        } else if (maxLength && value?.length !== maxLength) {
            setErrorMessage(`Input value must be less than ${maxLength} characters `);
            return;
        }
        const payload = {
            interaction_type: elementType, [elementType == 'NUMERIC_INPUT' ? 'value' : 'text']: value
        }
        handleNext(payload);
    }

    return (<form className={'w-full space-y-2'} onSubmit={handleSubmit}>
        {components?.[step]?.data?.message?.label && (<p>
            {components?.[step]?.data?.message?.label}
        </p>)}
        <Input
            required
            type={'text'}
            disabled={disabledField}
            maxLength={maxLength}
            minLength={minLength}
            max={maxLength}
            min={minLength}
            value={renderValue(elementType, components?.[step]?.value, components?.[step]?.data?.interface_block_id)?.toUpperCase()}
            onChange={handleInput}
        />
        {errorMessage && <span className={'text-red-400'}>{errorMessage}</span>}
        <div>
            <Button
                type={'button'}
                disabled={disabledField || appLoader}
                size={'sm'}
                style={{background: brandingData?.accent_colour ? '#' + brandingData?.accent_colour : '#000'}}
                onClick={handleSubmit}>
                Proceed
            </Button>
        </div>
    </form>)
}
