import React, { useEffect, useState } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from "/components/ui/form";
import { Input } from "/components/ui/input";
import { Button } from "/components/ui/button";
import { Select } from "/components/ui/select";
import { Textarea } from "/components/ui/textarea";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "/components/ui/input-otp.jsx";
import SelectInput from "../SelectInputComponent/SelectInput";
import { DateTimePicker } from "../DatePickerComponent/CustomeDatePickerCOmponent";
import { setFormInputFields } from "../../../slices/slices";
import { useDispatch, useSelector } from "react-redux";
import { setAppLoader } from "../../../slices/slices";
import { Loader2 } from "lucide-react";

// ... import other necessary components

const DynamicForm = ({  socket, handleNext, components, interfaceBlockId }) => {
  const methods = useForm();
  const { control } = methods;

  const { formInputFields, appLoader } = useSelector(
    (state) => state.userSlice
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [componentArray, setComponentArray] = useState(components);
  const [date, setDate] = useState(null);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage(null);
  
    const payload = {
      interaction_type: "FORM_INPUT",
      interface_block_id: interfaceBlockId,
    };

    const isData = componentArray.some(
      (item) => item?.value !== "" 
    );
   
    if (isData) {
      const payloadComponent = componentArray.map((item) => {
        if (item?.interaction_type == "FILE_INPUT") {
          return {
            interaction_type: item?.interaction_type,
            data: item?.value || item?.file,
          };
        } else if (item?.interaction_type == "TEXT_INPUT") {
          return {
            interaction_type: item?.interaction_type,
            text: item?.value,
          };
        } else if (item?.interaction_type == "NUMERIC_INPUT") {
          return {
            interaction_type: item?.interaction_type,
            value: item?.value,
          };
        } else if (item?.interaction_type == "OTP_INPUT") {
          return {
            interaction_type: item?.interaction_type,
            value: item?.value,
          };
        } else if (item?.interaction_type == "SELECT_INPUT") {
          const filterItem = item?.section?.[0]?.items?.filter((data) =>
            data?.name == item?.value ? data : null
          );
          console.log("filterItem", filterItem, item);
          return {
            interaction_type: item?.interaction_type,
            title: item?.value,
            reference_id: filterItem?.[0]?.reference_id,
          };
        } else if (item?.interaction_type == "DATE_INPUT") {
          return {
            interaction_type: item?.interaction_type,
            value: item?.value,
          };
        } else if (item?.interaction_type == "DATETIME_INPUT") {
          return {
            interaction_type: item?.interaction_type,
            value: item?.value,
          };
        }
        return undefined;
      });
    Object.assign(payload, {components: payloadComponent})
    handleNext( payload);
    } else {
     
      setErrorMessage("Please fill all the fields");
    }
  };

  function renderValue(interactionType, defaultValue, index) {
    console.log("defaultValue", defaultValue, interactionType, index);
    if (defaultValue) {
      return defaultValue;
    } else if (components.length) {
      const data = components.find(
        (item) => item?.interaction_type == interactionType
      );
      if (interactionType == "FILE_INPUT") {
        let obj = {
          image: `data:${data?.data?.content_type};base64,${data?.data?.data}`,
          filename: data?.data?.filename,
        };

        return obj;
      } else if (interactionType == "NUMERIC_INPUT") {
        return components?.[index]?.value;
      } else if (interactionType == "TEXT_INPUT") {
        return components?.[index]?.text;
      } else if (interactionType == "OTP_INPUT") {
        return components?.[index]?.text;
      } else if (interactionType == "SELECT_INPUT") {
        return components?.[index]?.title;
      }
      return undefined;
    }
    return undefined;
  }

  const handleChange = (component, value, index) => {
    const tempArray = componentArray?.map((item) => ({
      ...item,
      value: item?.value || "",
      file: item?.file || "",
    }));
    console.log("tempArray", tempArray, value);
    tempArray[index]["value"] = value;

    dispatch(setFormInputFields(tempArray));
  };

  const renderFormControl = (component, field, index) => {
    switch (component.interaction_type) {
      case "TEXT_INPUT":
        return (
          <Input
            required={true}
            {...field}
            value={renderValue("TEXT_INPUT", component?.value, index)}
            onChange={(e) => handleChange(component, e.target.value, index)}
          />
        );
      case "NUMERIC_INPUT":
        return (
          <Input
            required={true}
            value={renderValue("NUMERIC_INPUT", component?.value, index)}
            {...field}
            type="number"
            onChange={(e) => handleChange(component, e.target.value, index)}
          />
        );
      case "OTP_INPUT":
        const messageLength = component?.length || 4;
        return (
          <InputOTP
            maxLength={messageLength || 4}
            value={renderValue("OTP_INPUT", component?.value, index)}
            onChange={(value) => {
              handleChange(component, value, index);
            }}
          >
            {Array.from({ length: messageLength }).map((_, i) => {
              const groupSize =
                messageLength === 6 ? 3 : Math.ceil(messageLength / 2);

              return (
                <React.Fragment key={i}>
                  <InputOTPGroup>
                    <InputOTPSlot index={i} />
                  </InputOTPGroup>
                  {(i + 1) % groupSize === 0 && i + 1 < messageLength && (
                    <InputOTPSeparator>-</InputOTPSeparator>
                  )}
                </React.Fragment>
              );
            })}
          </InputOTP>
        );
      case "FILE_INPUT":
        return (
          <Input
            required={true}
            value={renderValue("FILE_INPUT", component?.value, index)}
            {...field}
            type="file"
            onChange={(e) => {
              const [file] = e.target.files;
              console.log("file", file);
              const fileReader = new FileReader();  
              fileReader.readAsDataURL(file);
              fileReader.onload = () => {
                handleChange(component, fileReader.result?.split('data:image/jpeg;base64,')[1], index);
              }
            }}
          />
        );
      case "SELECT_INPUT":
        return (
          <SelectInput
            onChange={(value) => handleChange(component, value, index)}
            component={components}
            index={index}
            formInput={true}
            renderValue={() =>
              renderValue("SELECT_INPUT", component?.value, index)
            }
          />
        );
      case "DATE_INPUT":
        return (
          <div className={"flex flex-col items-start gap-4"}>
            <DateTimePicker
              required={true}
              granularity="day"
              value={renderValue("DATE_INPUT", component?.value, index)}
              onChange={(value) => {
                handleChange(component, value, index);
                ``;
              }}
            />
            {errorMessage && (
              <span className={"text-red-400"}>{errorMessage}</span>
            )}
          </div>
        );
      case "DATETIME_INPUT":
        return (
          <div className={"flex flex-col items-start  gap-4"}>
            <DateTimePicker
              required={true}
              value={renderValue("DATETIME_INPUT", component?.value, index)}
              onChange={(value) => {
                handleChange(component, value, index);
              }}
            />

            {errorMessage && (
              <span className={"text-red-400"}>{errorMessage}</span>
            )}
          </div>
        );
      case "CAMERA_IMAGE_INPUT":
        return (
          <Input
            required={true}
            {...field}
            type="file"
            accept="image/*"
            capture="camera"
          />
        );
      default:
        return <Input {...field} />;
    }
  };

  useEffect(() => {
    if (formInputFields.length > 0) {
      setComponentArray(formInputFields);
    }
  }, [formInputFields]);

  console.log("componentArray", componentArray, formInputFields);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-xl mx-auto p-6 border border-gray-200 rounded-lg shadow-sm"
      >
        {componentArray.map((component, index) => (
          <Controller
            key={index}
            required={true}
            control={control}
            name={component.label.toLowerCase().replace(/\s+/g, "_")}
            render={({ field }) => (
              <FormItem className="mt-2">
                <FormLabel>
                  {component.label}
                  <span className="text-red-500">*</span>
                </FormLabel>
                <FormControl>
                  {renderFormControl(component, field, index)}
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
        ))}
        {errorMessage && <span className={"text-red-400"}>{errorMessage}</span>}
        <div className="flex justify-center mt-6">
          <Button
            type="button"
            className="px-6"
            disabled={appLoader}
            onClick={handleSubmit}
          >
            {appLoader ? <Loader2 className="animate-spin"/> : "Submit"}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default DynamicForm;
