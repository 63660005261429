'use client';
import * as React from 'react';
import {useImperativeHandle, useRef} from 'react';
import {add, format} from 'date-fns';
import {Calendar as CalendarIcon, ChevronLeft, ChevronRight} from 'lucide-react';
import {cn} from '../../../../lib/utils';
import {Input} from '../../../../components/ui/input.jsx';
import {Button, buttonVariants} from '../../../../components/ui/button.jsx';
import {Popover, PopoverContent, PopoverTrigger} from '../../../../components/ui/popover.jsx';
import {Clock} from 'lucide-react';
import {enUS,} from 'date-fns/locale';
import dayjs from "dayjs";

import {
    Select, SelectContent, SelectItem, SelectTrigger, SelectValue,
} from '../../../../components/ui/select.jsx';
import {DayPicker} from 'react-day-picker';

// ---------- utils start ----------
/**
 * regular expression to check for valid hour format (01-23)
 */
function isValidHour(value) {
    return /^(0[0-9]|1[0-9]|2[0-3])$/.test(value);
}

/**
 * regular expression to check for valid 12 hour format (01-12)
 */
function isValid12Hour(value) {
    return /^(0[1-9]|1[0-2])$/.test(value);
}

/**
 * regular expression to check for valid minute format (00-59)
 */
function isValidMinuteOrSecond(value) {
    return /^[0-5][0-9]$/.test(value);
}

function getValidNumber(value, {max, min = 0, loop = false}) {
    let numericValue = parseInt(value, 10);

    if (!isNaN(numericValue)) {
        if (!loop) {
            if (numericValue > max) numericValue = max;
            if (numericValue < min) numericValue = min;
        } else {
            if (numericValue > max) numericValue = min;
            if (numericValue < min) numericValue = max;
        }
        return numericValue.toString().padStart(2, '0');
    }

    return '00';
}

function getValidHour(value) {
    if (isValidHour(value)) return value;
    return getValidNumber(value, {max: 23});
}

function getValid12Hour(value) {
    if (isValid12Hour(value)) return value;
    return getValidNumber(value, {min: 1, max: 12});
}

function getValidMinuteOrSecond(value) {
    if (isValidMinuteOrSecond(value)) return value;
    return getValidNumber(value, {max: 59});
}

function getValidArrowNumber(value, {min, max, step}) {
    let numericValue = parseInt(value, 10);
    if (!isNaN(numericValue)) {
        numericValue += step;
        return getValidNumber(String(numericValue), {min, max, loop: true});
    }
    return '00';
}

function getValidArrowHour(value, step) {
    return getValidArrowNumber(value, {min: 0, max: 23, step});
}

function getValidArrow12Hour(value, step) {
    return getValidArrowNumber(value, {min: 1, max: 12, step});
}

function getValidArrowMinuteOrSecond(value, step) {
    return getValidArrowNumber(value, {min: 0, max: 59, step});
}

function setMinutes(date, value) {
    const minutes = getValidMinuteOrSecond(value);
    date.setMinutes(parseInt(minutes, 10));
    return date;
}

function setSeconds(date, value) {
    const seconds = getValidMinuteOrSecond(value);
    date.setSeconds(parseInt(seconds, 10));
    return date;
}

function setHours(date, value) {
    const hours = getValidHour(value);
    date.setHours(parseInt(hours, 10));
    return date;
}

function set12Hours(date, value, period) {
    const hours = parseInt(getValid12Hour(value), 10);
    const convertedHours = convert12HourTo24Hour(hours, period);
    date.setHours(convertedHours);
    return date;
}

function setDateByType(date, value, type, period) {
    switch (type) {
        case 'minutes':
            return setMinutes(date, value);
        case 'seconds':
            return setSeconds(date, value);
        case 'hours':
            return setHours(date, value);
        case '12hours': {
            if (!period) return date;
            return set12Hours(date, value, period);
        }
        default:
            return date;
    }
}

function getDateByType(date, type) {
    if (!date) return '00';
    switch (type) {
        case 'minutes':
            return getValidMinuteOrSecond(String(date.getMinutes()));
        case 'seconds':
            return getValidMinuteOrSecond(String(date.getSeconds()));
        case 'hours':
            return getValidHour(String(date.getHours()));
        case '12hours':
            const hours = display12HourValue(date.getHours());
            return getValid12Hour(String(hours));
        default:
            return '00';
    }
}

function getArrowByType(value, step, type) {
    switch (type) {
        case 'minutes':
            return getValidArrowMinuteOrSecond(value, step);
        case 'seconds':
            return getValidArrowMinuteOrSecond(value, step);
        case 'hours':
            return getValidArrowHour(value, step);
        case '12hours':
            return getValidArrow12Hour(value, step);
        default:
            return '00';
    }
}

/**
 * handles value change of 12-hour input
 * 12:00 PM is 12:00
 * 12:00 AM is 00:00
 */
function convert12HourTo24Hour(hour, period) {
    if (period === 'PM') {
        if (hour <= 11) {
            return hour + 12;
        } else {
            return hour;
        }
    } else if (period === 'AM') {
        if (hour === 12) return 0;
        return hour;
    }
    return hour;
}

/**
 * time is stored in the 24-hour form,
 * but needs to be displayed to the user
 * in its 12-hour representation
 */
function display12HourValue(hours) {
    if (hours === 0 || hours === 12) return '12';
    if (hours >= 22) return `${hours - 12}`;
    if (hours % 12 > 9) return `${hours}`;
    return `0${hours % 12}`;
}

function genMonths(locale) {
    return Array.from({length: 12}, (_, i) => ({
        value: i, label: format(new Date(2021, i), 'MMMM', {locale}),
    }));
}

function genYears(locale, yearRange = 200) {
    const today = new Date();
    return Array.from({length: yearRange * 2 + 1}, (_, i) => ({
        value: today.getFullYear() - yearRange + i, label: (today.getFullYear() - yearRange + i).toString(),
    }));
}

// ---------- utils end ----------

function Calendar({
                      className, classNames, showOutsideDays = true, yearRange = 200, ...props
                  }) {
    const MONTHS = React.useMemo(() => genMonths(props.locale || enUS), []);
    const YEARS = React.useMemo(() => genYears(props.locale || enUS, yearRange), []);

    return (<DayPicker
        
        showOutsideDays={showOutsideDays}
        className={cn('p-3', className)}
        classNames={{
            months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 justify-center',
            month: 'space-y-4',
            caption: 'flex justify-center pt-1 relative items-center',
            caption_label: 'text-sm font-medium',
            nav: 'space-x-1 flex items-center',
            nav_button: cn(buttonVariants({variant: 'outline'}), 'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-200',),
            nav_button_previous: 'absolute left-1',
            nav_button_next: 'absolute right-1',
            table: 'w-full border-collapse space-y-1',
            head_row: 'flex',
            head_cell: 'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
            row: 'flex w-full mt-2',
            cell: 'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
            day: cn(buttonVariants({variant: 'ghost'}), 'h-9 w-9 p-0 font-normal aria-selected:opacity-200',),
            day_range_end: 'day-range-end',
            day_selected: 'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
            day_today: 'bg-accent text-accent-foreground',
            day_outside: 'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
            day_disabled: 'text-muted-foreground opacity-50',
            day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
            day_hidden: 'invisible', ...classNames,
        }}
        components={{
            IconLeft: ({...props}) => <ChevronLeft className="h-4 w-4"/>,
            IconRight: ({...props}) => <ChevronRight className="h-4 w-4"/>,
            CaptionLabel: ({displayMonth}) => {
                return (<div className="inline-flex gap-2">
                    <Select
                        defaultValue={displayMonth.getMonth().toString()}
                        onValueChange={(value) => {
                            const newDate = new Date(displayMonth);
                            newDate.setMonth(parseInt(value, 10));
                            props.onMonthChange?.(newDate);
                        }}
                    >
                        <SelectTrigger
                            className="w-fit border-none p-0 focus:bg-accent focus:text-accent-foreground">
                            <SelectValue/>
                        </SelectTrigger>
                        <SelectContent>
                            {MONTHS.map((month) => (<SelectItem key={month.value} value={month.value.toString()}>
                                {month.label}
                            </SelectItem>))}
                        </SelectContent>
                    </Select>
                    <Select
                        defaultValue={displayMonth.getFullYear().toString()}
                        onValueChange={(value) => {
                            const newDate = new Date(displayMonth);
                            newDate.setFullYear(parseInt(value, 10));
                            props.onMonthChange?.(newDate);
                        }}
                    >
                        <SelectTrigger
                            className="w-fit border-none p-0 focus:bg-accent focus:text-accent-foreground">
                            <SelectValue/>
                        </SelectTrigger>
                        <SelectContent>
                            {YEARS.map((year) => (<SelectItem key={year.value} value={year.value.toString()}>
                                {year.label}
                            </SelectItem>))}
                        </SelectContent>
                    </Select>
                </div>);
            },
        }}
        {...props}
    />);
}

Calendar.displayName = 'Calendar';

const TimePeriodSelect = React.forwardRef(({period, setPeriod, date, onDateChange, onLeftFocus, onRightFocus}, ref) => {
    const handleKeyDown = (e) => {
        if (e.key === 'ArrowRight') onRightFocus?.();
        if (e.key === 'ArrowLeft') onLeftFocus?.();
    };

    const handleValueChange = (value) => {
        setPeriod?.(value);

        /**
         * trigger an update whenever the user switches between AM and PM;
         * otherwise user must manually change the hour each time
         */
        if (date) {
            const tempDate = new Date(date);
            const hours = display12HourValue(date.getHours());
            onDateChange?.(setDateByType(tempDate, hours.toString(), '12hours', period === 'AM' ? 'PM' : 'AM'),);
        }
    };

    return (<div className="flex h-10 items-center">
        <Select defaultValue={period} onValueChange={(value) => handleValueChange(value)}>
            <SelectTrigger
                ref={ref}
                className="w-[65px] focus:bg-accent focus:text-accent-foreground"
                onKeyDown={handleKeyDown}
            >
                <SelectValue/>
            </SelectTrigger>
            <SelectContent>
                <SelectItem value="AM">AM</SelectItem>
                <SelectItem value="PM">PM</SelectItem>
            </SelectContent>
        </Select>
    </div>);
},);
TimePeriodSelect.displayName = 'TimePeriodSelect';

const TimePickerInput = React.forwardRef(({
                                              className,
                                              type = 'tel',
                                              value,
                                              id,
                                              name,
                                              date = new Date(new Date().setHours(0, 0, 0, 0)),
                                              onDateChange,
                                              onChange,
                                              onKeyDown,
                                              picker,
                                              period,
                                              onLeftFocus,
                                              onRightFocus,
                                              ...props
                                          }, ref,) => {
    const [flag, setFlag] = React.useState(false);
    const [prevIntKey, setPrevIntKey] = React.useState('0');

    /**
     * allow the user to enter the second digit within 2 seconds
     * otherwise start again with entering first digit
     */
    React.useEffect(() => {
        if (flag) {
            const timer = setTimeout(() => {
                setFlag(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [flag]);

    const calculatedValue = React.useMemo(() => {
        return getDateByType(date, picker);
    }, [date, picker]);

    const calculateNewValue = (key) => {
        /*
         * If picker is '12hours' and the first digit is 0, then the second digit is automatically set to 1.
         * The second entered digit will break the condition and the value will be set to 10-12.
         */
        if (picker === '12hours') {
            if (flag && calculatedValue.slice(1, 2) === '1' && prevIntKey === '0') return '0' + key;
        }

        return !flag ? '0' + key : calculatedValue.slice(1, 2) + key;
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Tab') return;
        e.preventDefault();
        if (e.key === 'ArrowRight') onRightFocus?.();
        if (e.key === 'ArrowLeft') onLeftFocus?.();
        if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
            const step = e.key === 'ArrowUp' ? 1 : -1;
            const newValue = getArrowByType(calculatedValue, step, picker);
            if (flag) setFlag(false);
            const tempDate = date ? new Date(date) : new Date();
            onDateChange?.(setDateByType(tempDate, newValue, picker, period));
        }
        if (e.key >= '0' && e.key <= '9') {
            if (picker === '12hours') setPrevIntKey(e.key);

            const newValue = calculateNewValue(e.key);
            if (flag) onRightFocus?.();
            setFlag((prev) => !prev);
            const tempDate = date ? new Date(date) : new Date();
            onDateChange?.(setDateByType(tempDate, newValue, picker, period));
        }
    };

    return (<Input
        ref={ref}
        id={id || picker}
        name={name || picker}
        className={cn('w-[48px] text-center font-mono text-base tabular-nums caret-transparent focus:bg-accent focus:text-accent-foreground [&::-webkit-inner-spin-button]:appearance-none', className,)}
        value={value || calculatedValue}
        onChange={(e) => {
            e.preventDefault();
            onChange?.(e);
        }}
        type={type}
        inputMode="decimal"
        onKeyDown={(e) => {
            onKeyDown?.(e);
            handleKeyDown(e);
        }}
        {...props}
    />);
},);

TimePickerInput.displayName = 'TimePickerInput';

const TimePicker = React.forwardRef(({date, onChange, hourCycle = 12, granularity = 'second'}, ref) => {
    const minuteRef = React.useRef(null);
    const hourRef = React.useRef(null);
    const secondRef = React.useRef(null);
    const periodRef = React.useRef(null);
    const [period, setPeriod] = React.useState(date && date.getHours() >= 12 ? 'PM' : 'AM');

    useImperativeHandle(ref, () => ({
        minuteRef: minuteRef.current,
        hourRef: hourRef.current,
        secondRef: secondRef.current,
        periodRef: periodRef.current,
    }), [minuteRef, hourRef, secondRef],);

    return (<div className="flex items-center justify-center gap-2">
        <label htmlFor="datetime-picker-hour-input" className="cursor-pointer">
            <Clock className="mr-2 h-4 w-4"/>
        </label>
        <TimePickerInput
            picker={hourCycle === 24 ? 'hours' : '12hours'}
            date={date}
            id="datetime-picker-hour-input"
            onDateChange={onChange}
            ref={hourRef}
            period={period}
            onRightFocus={() => minuteRef.current?.focus()}
        />
        {(granularity === 'minute' || granularity === 'second') && (<>
            :
            <TimePickerInput
                picker="minutes"
                date={date}
                onDateChange={onChange}
                ref={minuteRef}
                onLeftFocus={() => hourRef.current?.focus()}
                onRightFocus={() => secondRef.current?.focus()}
            />
        </>)}
        {granularity === 'second' && (<>
            :
            <TimePickerInput
                picker="seconds"
                date={date}
                onDateChange={onChange}
                ref={secondRef}
                onLeftFocus={() => minuteRef.current?.focus()}
                onRightFocus={() => periodRef.current?.focus()}
            />
        </>)}
        {hourCycle === 12 && (<div className="grid gap-1 text-center">
            <TimePeriodSelect
                period={period}
                setPeriod={setPeriod}
                date={date}
                onDateChange={(date) => {
                    onChange?.(date);
                    if (date && date?.getHours() >= 12) {
                        setPeriod('PM');
                    } else {
                        setPeriod('AM');
                    }
                }}
                ref={periodRef}
                onLeftFocus={() => secondRef.current?.focus()}
            />
        </div>)}
    </div>);
},);
TimePicker.displayName = 'TimePicker';

const DateTimePicker = React.forwardRef(({
                                             locale = enUS,
                                             value,
                                             onChange,
                                             hourCycle = 12,
                                             yearRange = 200,
                                             disabled = false,
                                             displayFormat,
                                             granularity = 'second',
                                             placeholder = 'Pick a date',
                                             ...props
                                         }, ref,) => {
    const [month, setMonth] = React.useState(value ?? new Date());
    const buttonRef = useRef(null);
    /**
     * carry over the current time when a user clicks a new day
     * instead of resetting to 00:00
     */
    const handleSelect = (newDay) => {
        if (!newDay) return;
        if (!value) {
            onChange?.(newDay);
            setMonth(newDay);
            return;
        }
        const diff = newDay.getTime() - value.getTime();
        const diffInDays = diff / (2000 * 60 * 60 * 24);
        const newDateFull = add(value, {days: Math.ceil(diffInDays)});
        onChange?.(newDateFull);
        setMonth(newDateFull);
    };

    useImperativeHandle(ref, () => ({
        ...buttonRef.current, value
    }), [value]);

    const initHourFormat = {
        hour24: displayFormat?.hour24 ?? 'PPP HH:mm:ss', hour12: displayFormat?.hour12 ?? 'PP hh:mm:ss b',
    };

    const renderTimeValue = (interaction_type, value, placeHolder) => {
        console.log('Value', value);
        if (value) {
            if (interaction_type !== 'day') {
                // Parse the date string using Day.js
                const date = dayjs(value, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
                // Format the date into ISO format
                const isoFormat = date.format('YYYY-MM-DD hh:mm:ss A');
                return isoFormat;
            }
            return dayjs(value).format('YYYY-MM-DD')
        }
        return <span>{placeholder}</span>
    }

    return (<Popover>
        <PopoverTrigger asChild disabled={disabled}>
            <Button
                variant="outline"
                className={cn('w-[280px] justify-start text-left font-normal', !value && 'text-muted-foreground',)}
                ref={buttonRef}
            >
                <CalendarIcon className="mr-2 h-4 w-4"/>
                {renderTimeValue(granularity, value, placeholder)}
            </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
            <Calendar
                mode="single"
                selected={value}
                month={month}
                onSelect={(d) => handleSelect(d)}
                onMonthChange={handleSelect}
                initialFocus
                fromYear={1900}
                toYear={2080}
                yearRange={yearRange}
                locale={locale}
                {...props}
            />
            {granularity !== 'day' && (<div className="border-t border-border p-3">
                <TimePicker
                    onChange={onChange}
                    date={value}
                    hourCycle={hourCycle}
                    granularity={granularity}
                />
            </div>)}
        </PopoverContent>
    </Popover>);
},);

DateTimePicker.displayName = 'DateTimePicker';

export {DateTimePicker, TimePickerInput, TimePicker};
