import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button.jsx";
import { cn } from "../../../../lib/utils.js";
import { useDispatch, useSelector } from "react-redux";
import {
  setAppLoader,
  setError,
  setFieldValue,
  setOtpSent,
  setSteps,
} from "../../../slices/slices.js";
import dayjs from "dayjs";
import { AnimatePresence, motion, steps, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { callNextEventAPI } from "../../../services/api/api.js";

import { replace, startCase } from "lodash";

import errorSvg from "/assets/error.jpg";
import ElementComp from "../../components/ElementComponent/ElementComponent.jsx";
import TextInputComp from "../../components/TextInputComponent/TextInputComponent.jsx";
import TextOtpInput from "../../components/TextOtpInput/TextOtpInput.jsx";
import SelectComp from "../../components/SelectInputComponent/SelectInput.jsx";
import FileInputComp from "../../components/FileInputComponent/FileInputComponent.jsx";
import CaptureComponent from "../../components/CaptureComponent/CaptureComponent.jsx";
import complete from "/assets/complete.jpg";
import DynamicForm from "../../components/FormInputComponent/FormInput.jsx";

import {
  DateTimePicker,
  TimePicker,
} from "../../components/DatePickerComponent/CustomeDatePickerCOmponent.jsx";

export default function ProductionItem(props) {
  const { data, index, currentIndex, socket } = props;
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [componentArray, setComponentArray] = useState([]);

  const {
    interfaceSession,
    brandingData,
    error,
    otpSent,
    appLoader,
    step,
    isPrevious,
    components,
    replayComponentsData,
    traceData,
    progressState,
  } = useSelector((state) => state.userSlice);

  const [errorMessage, setErrorMessage] = useState("");
const [element, setElement] = useState([])
  const control = useAnimation();
  const { ref, inView } = useInView({ threshold: 0.5 });
  const backCardVariants = useMemo(
    () => ({
      offscreen: {
        y: -100,
        opacity: 0,
      },
      onscreen: {
        y: 10,
        opacity: 1,
        transition: {
          duration: 0.5,
        },
      },
    }),
    [index]
  );

  const cardVariants = useMemo(
    () => ({
      offscreen: {
        y: 300,
        opacity: 0,
      },
      onscreen: {
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
        },
      },
    }),
    [index]
  );

  const dispatch = useDispatch();
  const responseSecond = useRef(0);
  const responseTimerRef = useRef();

  const handleInput = (e) => {
    let componentType = "";
    if (components?.[step]?.data?.message?.hasOwnProperty("interaction_type")) {
      componentType = components?.[step]?.data?.message?.interaction_type;
    } else {
      componentType = components?.[step]?.data?.message?.message_type;
    }
    const { value } = e.target;
    const tempArray = components?.map((item) => ({
      ...item,
      value: item.value || "",
      file: item.file || "",
    }));
    if (componentType == "NUMERIC_INPUT") {
      let numericValue = value?.replace(/[^0-9]/g, "");
      tempArray[step]["value"] = numericValue;
    } else {
      tempArray[step]["value"] = value;
    }
    dispatch(setFieldValue(tempArray));
  };

  const handleNext = (payload) => {
     console.log("Payload", payload, components[step]?.data?.message?.interaction_type);
    setErrorMessage("");

    if (components[step]?.value || components[step]?.file || components[step]?.data?.message?.interaction_type=='FORM_INPUT') {

      const blockId = components?.[step]?.data?.interface_block_id;

      if (blockId) {
        Object.assign(payload, { interface_block_id: blockId });
      }

      dispatch(setAppLoader(true));
      responseSecond.current = 0;
      socket.emit("interface_interaction", payload);
    }
  };

  const disabledField = useMemo(() => {
    let disabled = false;
    const startWidgetBlock = traceData.find(
      (item) => item?.data?.interface_block_type == "on_start_widget_block"
    );
    if (
      components?.[step]?.data?.interface_block_type ==
        "flash_message_widget_block" &&
      startWidgetBlock?.current_status !== "COMPLETED" &&
      startWidgetBlock?.current_status !== "FAILED"
    ) {
      disabled = false;
      return disabled;
    }
    const isStatusCompleted = traceData?.some(
      (data) =>
        data?.data?.interface_block_id ===
          components?.[step]?.data?.interface_block_id &&
        data?.data?.message?.block_current_status === "COMPLETED"
    );
    if (isStatusCompleted) {
      disabled = true;
    }
    return disabled;
  }, [traceData, components, step]);

  function getHandlePending() {
    responseTimerRef.current = setInterval(() => {
      responseSecond.current += 1;
      if (responseSecond.current == 60) {
        console.log("responseSecond", responseSecond.current);
        responseSecond.current = 0;
        dispatch(setAppLoader(false));
        responseTimerRef.current = null;
        clearInterval(responseTimerRef.current);
        let redirectUrl='';
        if(interfaceSession?.redirect_url?.includes('?') ){
          redirectUrl=`${interfaceSession?.redirect_url}&status=pending`
        }else{
          redirectUrl = `${
              interfaceSession?.redirect_url
          }/?status=pending`;
        }
          window.open(redirectUrl, "_self");
      }
    }, 1000);
  }

  useEffect(() => {
    if (appLoader) {
      getHandlePending();
    }
  }, [appLoader]);

  useEffect(() => {
    if (components?.length !== element?.length) {
      // const surepassFilter = components?.filter(item => progressState?.some(state => state?.group == 'SUREPASS' || state?.group == 'WIDGETS.RECIEVE' || state?.block_type=='flash_message_widget_block'));
      setElement(components);
      clearInterval(responseTimerRef.current);
    }
    if (components?.[step]?.data?.message?.message_type == "TERMINATE") {

      if (interfaceSession?.redirect_url) {
        let redirectUrl='';
        if(interfaceSession?.redirect_url?.includes('?')){
          redirectUrl=`${interfaceSession?.redirect_url}&status=completed`
        }else{
          redirectUrl = `${
              interfaceSession?.redirect_url
          }/?status=completed`;
        }
        setTimeout(() => {
          window.open(redirectUrl, "_self");
        }, 1000);
      }
    }
    const isStatusHalt = traceData?.some(
      (data) =>
        data?.data?.interface_block_type ===
          components?.[step]?.data?.interface_block_type &&
        data?.data?.message?.block_current_status === "FAILED"
    );

    if (
      isStatusHalt ||
      components?.[step]?.data?.message?.message_type == "HALT"
    ) {
      if (interfaceSession?.redirect_url) {
        let redirectUrl='';
        if(interfaceSession?.redirect_url?.includes('?')){
          redirectUrl=`${interfaceSession?.redirect_url}&status=failed`
        }else{
          redirectUrl = `${
              interfaceSession?.redirect_url
          }/?status=failed`;
        }

        setTimeout(() => {
          window.open(redirectUrl, "_self");
        }, 1000);
      }
      return;
    }
  }, [index, currentIndex, components]);

  function renderValue(interactionType, defaultValue, block_id) {
    if (defaultValue) {
      return defaultValue;
    } else if (replayComponentsData.length) {
      const data = replayComponentsData.find(
        (item) =>
          item.data.interaction_type == interactionType &&
          item?.data?.interface_block_id == block_id
      );
      console.log("Data",data);
      if (
        interactionType == "FILE_INPUT" &&
        data?.data?.interaction_type == interactionType
      ) {
        let obj = {
          image: `data:${data?.data?.content_type};base64,${data?.data?.data}`,
          filename: data?.data?.filename,
        };

        return obj;
      } else if (
        interactionType == "NUMERIC_INPUT" &&
        data?.data?.interaction_type == interactionType
      ) {
        return data?.data?.value;
      } else if (
        interactionType == "TEXT_INPUT" &&
        data?.data?.interaction_type == interactionType
      ) {
        return data?.data?.text;
      } else if (
        interactionType == "OTP_INPUT" &&
        data?.data?.interaction_type == interactionType
      ) {
        return data?.data?.value;
      } else if (
        interactionType == "SELECT_INPUT" &&
        data?.data?.interaction_type == interactionType
      ) {
        return data?.data?.title;
      } else if (
        interactionType == "ELEMENT" &&
        data?.data?.interaction_type == interactionType
      ) {
        return data?.data?.text;
      } else if (
        interactionType == "START_BUTTON" &&
        data?.data?.interaction_type == interactionType
      ) {
        return true;
      } else if (
        interactionType == "CAMERA_IMAGE_INPUT" &&
        data?.data?.interaction_type == interactionType
      ) {
        return {
          image: `data:${data?.data?.content_type};base64,${data?.data?.data}`,
          filename: data?.data?.filename,
        };
      }
      return undefined;
    }
    return undefined;
  }

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  const renderTitle = useCallback(() => {
    // const currentComponentItem = traceData.find(state => state?.data?.message?.block_type == item.data?.interface_block_type && state?.data?.message?.block_id == item?.data?.interface_block_id);
    const currentStepData = progressState.find(
      (state) => state.block_id === components[step]?.data?.interface_block_id
    );
    return currentStepData;
  }, [traceData, step, progressState, components]);

  function renderField() {
    let componentType = "";
    if (components?.[step]?.data?.message?.hasOwnProperty("interaction_type")) {
      componentType = components?.[step]?.data?.message?.interaction_type;
    } else {
      componentType = components?.[step]?.data?.message?.message_type;
    }
    console.log("Component Type", componentType,components);
    switch (componentType) {
      case "ELEMENT":
        return (
          <ElementComp
            handleNext={handleNext}
            disabledField={disabledField}
            socket={socket}
          />
        );
      case "FORM_INPUT":
        return <DynamicForm  socket={socket} handleNext={handleNext} components={components[step]?.data?.message?.components} interfaceBlockId={components[step]?.data?.interface_block_id} />;
      case "START_BUTTON":
        const isFlashMessage = components?.some(
          (item) =>
            item?.data?.message?.message_type == "ELEMENT" &&
            item?.data?.interface_block_type == "flash_message_widget_block"
        );
        if (!isFlashMessage) {
          return (
            <div className={"space-y-4"}>
              <div>
                {components?.[step]?.data?.message?.label ? (
                  <h1
                    className={
                      "text-[32px] text-left sm:text-[24px] font-semibold leading-[48px]"
                    }
                  >
                    {components?.[step]?.data?.message?.label}
                  </h1>
                ) : (
                  <>
                    <h1
                      className={
                        "text-[32px] text-left sm:text-[24px] font-semibold leading-[48px]"
                      }
                    >
                      Let's verify your KYC
                    </h1>
                    <p>
                      Please submit your details to complete your onboading
                      journey.
                    </p>
                  </>
                )}
              </div>
              <Button
                type={"button"}
                size={"sm"}
                disabled={appLoader}
                style={{
                  background: brandingData?.accent_colour
                    ? "#" + brandingData?.accent_colour
                    : "#000",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  let payload = {
                    interaction_type: "START_BUTTON",
                    interface_block_id:
                      components?.[step]?.data?.interface_block_id,
                  };
                  dispatch(setAppLoader(true));
                  socket.emit("interface_interaction", payload);
                }}
              >
                Proceed
              </Button>
            </div>
          );
        }
        return null;
      case "DATE_INPUT":
        return (
          <form
            className={"w-full space-y-2"}
            onSubmit={(e) => e.preventDefault()}
          >
            {components?.[step]?.data?.message?.label && (
              <p>{components?.[step]?.data?.message?.label}</p>
            )}
            <div className={"flex flex-col items-start gap-4"}>
              <DateTimePicker
                granularity="day"
                value={renderValue(
                  "DATE_INPUT",
                  components?.[step]?.value,
                  components?.[step]?.data?.interface_block_id
                )}
                onChange={(value) => {
                  setDate(value);
                  const tempArray = components?.map((item) => ({
                    ...item,
                    value: item.value || "",
                    file: item.file || "",
                  }));
                  tempArray[step]["value"] = value;
                  dispatch(setFieldValue(tempArray));
                }}
              />
              {errorMessage && (
                <span className={"text-red-400"}>{errorMessage}</span>
              )}
              <Button
                type={"button"}
                style={{
                  background: brandingData?.accent_colour
                    ? "#" + brandingData?.accent_colour
                    : "#000",
                }}
                disabled={disabledField}
                onClick={(e) => {
                  e.preventDefault();
                  if (!components?.[step]?.value) {
                    setErrorMessage("Please enter the date");
                    return;
                  }
                  const payload = {
                    interaction_type: "DATE_INPUT",
                    value: dayjs(date).format("YYYY-MM-DD"),
                  };
                  handleNext(payload);
                }}
              >
                Proceed
              </Button>
            </div>
          </form>
        );
      case "DATETIME_INPUT":
        return (
          <form
            className={"w-full space-y-2"}
            onSubmit={(e) => e.preventDefault()}
          >
            {components?.[step]?.data?.message?.label && (
              <p>{components?.[step]?.data?.message?.label}</p>
            )}
            <div className={"flex flex-col items-start  gap-4"}>
              <DateTimePicker
                value={renderValue(
                  "DATETIME_INPUT",
                  components?.[step]?.value,
                  components?.[step]?.data?.interface_block_id
                )}
                onChange={(value) => {
                  const tempArray = components?.map((item) => ({
                    ...item,
                    value: item.value || "",
                    file: item.file || "",
                  }));
                  tempArray[step]["value"] = value;
                  dispatch(setFieldValue(tempArray));
                }}
              />

              {errorMessage && (
                <span className={"text-red-400"}>{errorMessage}</span>
              )}
              <Button
                type={"button"}
                style={{
                  background: brandingData?.accent_colour
                    ? "#" + brandingData?.accent_colour
                    : "#000",
                }}
                disabled={disabledField}
                onClick={(e) => {
                  e.preventDefault();
                  if (!components?.[step]?.value) {
                    setErrorMessage("Please fill the required field");
                    return;
                  }
                  const payload = {
                    interaction_type: "DATETIME_INPUT",
                    value: components?.[step]?.value,
                  };
                  handleNext(payload);
                }}
              >
                Proceed
              </Button>
            </div>
          </form>
        );
      case "SELECT_INPUT":
        return (
          <SelectComp
            renderValue={renderValue}
            handleNext={handleNext}
            disabledField={disabledField}
          />
        );
      case "NUMERIC_INPUT":
        return (
          <TextInputComp
            elementType={componentType}
            renderValue={renderValue}
            handleNext={handleNext}
            disabledField={disabledField}
            handleInput={handleInput}
          />
        );
      case "TEXT_INPUT":
        return (
          <TextInputComp
            elementType={componentType}
            renderValue={renderValue}
            handleNext={handleNext}
            disabledField={disabledField}
            handleInput={handleInput}
          />
        );
      case "OTP_INPUT":
        return (
          <TextOtpInput
            handleInput={handleInput}
            renderValue={renderValue}
            handleNext={handleNext}
            disabledField={disabledField}
          />
        );
      case "CAMERA_IMAGE_INPUT":
        return <CaptureComponent renderValue={renderValue} />;
      case "FILE_INPUT":
        return (
          <FileInputComp
            renderValue={renderValue}
            disabledField={disabledField}
          />
        );
      case "TERMINATE":
        const isDeadCase = traceData.some(
          (item) => item?.data?.message?.block_current_status == "FAILED"
        );
        if (isDeadCase) {
          return (
            <div
              className={
                "w-full flex flex-col  justify-center items-center gap-2"
              }
            >
              <img
                src={errorSvg}
                className="w-full max-w-[300px] sm:max-w-[200px] object-contain"
                alt="Error illustration"
              />

              <h1
                className={
                  "text-[24px] sm:text-[20px] font-semibold text-center"
                }
              >
                Oops! Something went wrong!
              </h1>
              <span className={"text-[20px] sm:text-[16px] text-center"}>
                We regret to inform you that the verification process was
                unsuccessful.
              </span>
            </div>
          );
        }
        return (
          <div
            className={"w-full flex flex-col justify-center items-center gap-4"}
          >
            <img
              src={complete}
              className="w-full  max-w-[300px] sm:max-w-[200px] object-contain"
              alt="Error illustration"
            />
            <p
              className={"text-[24px] font-semibold sm:text-[20px] text-center"}
            >
              Congratulations! The flow has been successfully completed without
              any issues.
            </p>
          </div>
        );
      case "HALT":
        return (
          <div
            className={
              "w-full flex flex-col  justify-center items-center gap-4"
            }
          >
            <img
              src={errorSvg}
              className="w-full  max-w-[300px] sm:max-w-[200px]  object-contain"
              alt="Error illustration"
            />
            <h1
              className={"text-[24px] sm:text-[20px] font-semibold text-center"}
            >
              Oops! Something went wrong!
            </h1>
            <p className="text-[20px] sm:text-[16px] text-center">
              Please try again later. If the issue continues to occur, please
              contact our technical support team for assistance.
            </p>
          </div>
        );
      case "TRACE":
        const isStatusCompleted = traceData?.some(
          (data) =>
            data?.data?.interface_block_type ===
              components?.[step]?.data?.interface_block_type &&
            data?.data?.message?.block_current_status === "COMPLETED"
        );

        const isStatusHalt = traceData?.some(
          (data) =>
            data?.data?.interface_block_type ===
              components?.[step]?.data?.interface_block_type &&
            data?.data?.message?.block_current_status === "FAILED"
        );

        const renderTitleState = renderTitle();

        if (isStatusCompleted) {
          return (
            <div
              className={
                "w-full flex flex-col justify-center items-center gap-4"
              }
            >
              <img
                src={complete}
                className="w-full max-w-[300px] sm:max-w-[200px]  object-contain"
                alt="Complete illustration"
              />
              {renderTitleState && (
                <div className={"space-y space-y-2"}>
                  <h1
                    className={
                      "text-[24px] sm:text-[20px] font-semibold text-center"
                    }
                  >
                    {startCase(renderTitleState?.humanized_label)}
                  </h1>
                  <p className={"text-[20px] sm:text-[16px] text-center"}>
                    {startCase(renderTitleState?.description)}
                  </p>
                </div>
              )}
            </div>
          );
        }
        if (isStatusHalt) {
          const title =
            components[step]?.data?.message?.safe_error_message || "";
          return (
            <div
              className={
                "w-full flex flex-col justify-center items-center gap-4"
              }
            >
              <img
                src={errorSvg}
                className="w-full object-contain max-w-[300px] sm:max-w-[200px]"
                alt="Error illustration"
              />
              {title ? (
                <p className="text-[24px] font-semibold sm:text-[16px] text-center ">
                  {title}
                </p>
              ) : (
                <p
                  className={
                    "text-[24px] font-semibold sm:text-[16px] text-center"
                  }
                >
                  We're sorry, but we were unable to verify your information at
                  this time. Please double-check the information you provided
                  and try again. If the problem persists, please contact our
                  support team for assistance. Thank you for your
                  understanding."
                </p>
              )}
            </div>
          );
        }
        return null;
      default:
        return null;
    }
  }



  return (
    <motion.div
      className={`w-full h-full flex ${
        components?.[step]?.data?.message?.interaction_type ==
          "CAMERA_IMAGE_INPUT" ||
        components?.[step]?.data?.message?.message_type == "CAMERA_IMAGE_INPUT"
          ? "items-center sm:items-start"
          : "items-center"
      }  ${
        appLoader ||
        components?.[step]?.data?.message?.interaction_type == "FILE_INPUT"
          ? "justify-center"
          : "justify-start"
      } sm:justify-center gap-4 `}
      key={index}
      ref={index == currentIndex ? ref : null}
      transition={{ duration: 0.5 }}
      initial="offscreen"
      whileInView="onscreen"
      animate={control}
      variants={isPrevious ? backCardVariants : cardVariants}
    >
      {renderField()}
    </motion.div>
  );
}
